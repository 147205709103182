import React from "react";

const About = () => {
  return (
    <div
      name="about"
      className="w-full h-screen bg-gradient-to-b from-gray-800 to-black text-white"
    >
      <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">
        <div className="pb-8">
          <p className="text-4xl font-bold inline border-b-4 border-gray-500">
            About
          </p>
        </div>

        <p className="text-xl mt-5">
          My name is Asmaa Abdul-Amin. I began my web development career at the end of 2021. I am self taught and I am currently pursuing my degree in Computer Science. I have also completed Harvard's CS50 Introduction to Computer Science, Harvard's CS50 Web Programming with Python and JavaScript, and Harvard's CS50 Introduction to Programming with Python.
        </p>

        <br />

        <p className="text-xl">
        I can provide clean code and pixel perfect design. I also am able to make your website increasingly interactive with web animations. A responsive design makes your website accessable to all users, regardless of their device.
        </p>
      </div>
    </div>
  );
};

export default About;